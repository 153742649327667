import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'

export default () => {
    const { allWordpressCategory } = useStaticQuery(graphql`
        query {
            allWordpressCategory(skip: 0, limit: 3) {
                edges {
                    node {
                        id
                        name
                        slug
                    }
                }
            }
        }
    `)

    return (
        <section className="Guide">
            <h2 className="Guide__title"><span>カ</span>テゴリー</h2>
            <ul className="Guide__items">
                {allWordpressCategory.edges.map(({ node }) => (
                    <li key={node.id}>
                        <Link to={`/cat/${node.slug}/`}>
                            {node.name}
                        </Link>
                    </li>
                ))}
            </ul>
        </section>
    )
}

import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'

export default () => {
    const { allWordpressPost } = useStaticQuery(graphql`
        query {
            allWordpressPost(sort: {order: DESC, fields: date}, skip: 0, limit: 3) {
                edges {
                    node {
                        title
                        id
                        slug
                    }
                }
            }
        }
    `)

    return (
        <section className="Guide">
            <h2 className="Guide__title"><span>最</span>近の投稿</h2>
            <ul className="Guide__items">
                {allWordpressPost.edges.map(({ node }) => (
                    <li key={node.id}>
                        <Link to={`/blog/post/${node.slug}/`}>
                            {node.title}
                        </Link>
                    </li>
                ))}
            </ul>
        </section>
    )
}

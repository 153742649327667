import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'

export default () => {
    const { allWordpressPost } = useStaticQuery(graphql`
        query {
            allWordpressPost(sort: {order: DESC, fields: date}) {
                edges {
                    node {
                        date(formatString: "YYYY-MM")
                    }
                }
            }
        }
    `)

    const uniqueDate = allWordpressPost.edges.map(item => item.node.date).filter((x, i, self) => self.indexOf(x) === i).slice(0,3)

    return (
        <section className="Guide">
            <h2 className="Guide__title"><span>ア</span>ーカイブ</h2>
            <ul className="Guide__items">
                {uniqueDate.map(item => (
                    <li key={item}>
                        <Link to={`/date/${item}/`}>
                            {`${item.replace("-", "月")}日`}
                        </Link>
                    </li>
                ))}
            </ul>
        </section>
    )
}

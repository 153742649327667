import React from 'react'
import { graphql, Link } from 'gatsby'
import AniLink from "gatsby-plugin-transition-link/AniLink"
import Layout from '../components/layout/layout'
import SEO from '../components/seo/seo'
import NewArticle from '../components/newArticle/newArticle'
import NewCategory from '../components/newCategory/newCategory'
import NewArchive from '../components/newArchive/newArchive'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenNib, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'

export default ({ data, pageContext, location }) => (
    <Layout>
        <SEO
            pagetitle={data.wordpressPost.title}
            pagedesc={`${data.wordpressPost.excerpt.replace(/<("[^"]*"|'[^']*'|[^'">])*>/g, '').slice(0, 70)}…`}  //htmlタグを取り除いた上で70文字までを表示
            pagepath={location.pathname}
            pageimg={data.wordpressPost.featured_media && data.wordpressPost.featured_media.source_url}
            pageimgw={data.wordpressPost.featured_media && data.wordpressPost.featured_media.media_details.width}
            pageimgh={data.wordpressPost.featured_media && data.wordpressPost.featured_media.media_details.height}
        />
        <main className="SinglePage">
            <section className="SinglePage__section1">
                <div className="l-column">
                    <div className="BlogPost">
                        <div className="BlogPost__contents">
                            {/*メイン*/}
                            <div className="BlogPost__main">
                                <div className="BlogPost__head">
                                    <div className="BlogPost__shoulder">
                                        <time className="BlogPost__time" dateTime={data.wordpressPost.date}><FontAwesomeIcon icon={faPenNib} className="BlogPost__fontAwesomIcon" />{data.wordpressPost.dateJP}</time>
                                    </div>
                                    <h1 className="BlogPost__title">{data.wordpressPost.title}</h1>
                                    <ul className="BlogPost__categories">
                                        {data.wordpressPost.categories.map(cat => (
                                            <li className="BlogPost__category" key={cat.id}>
                                                カテゴリー： <Link to={`/cat/${cat.slug}/`}>{cat.name}</Link>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <div
                                    className="BlogPost__body"
                                    dangerouslySetInnerHTML={{ __html: data.wordpressPost.content }}
                                />
                                <div className="BlogPost__foot">
                                    <div className="Pagination">
                                        <ul className="Pagination__postlink">
                                            {pageContext.previous && (
                                                <li className="Pagination__prev">
                                                    <Link to={`/blog/post/${pageContext.previous.slug}/`} rel="next">
                                                        <FontAwesomeIcon icon={faChevronLeft} className="Pagination__prevFontAwesomIcon" />
                                                        <span>前のページ</span>
                                                    </Link>
                                                </li>
                                            )}
                                            {pageContext.next && (
                                                <li className="Pagination__next">
                                                    <Link to={`/blog/post/${pageContext.next.slug}/`} rel="prev">
                                                        <span>次のページ</span>
                                                        <FontAwesomeIcon icon={faChevronRight} className="Pagination__nextFontAwesomIcon" />
                                                    </Link>
                                                </li>
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {/*サイドバー*/}
                            <aside className="BlogPost__sub">
                                <header className="BlogPost__asideHead">
                                    <div className="BlogPost__asideLogo">
                                        <h1>
                                            <AniLink
                                                fade
                                                to={`/`}
                                                duration={1}
                                            >
                                                <svg version="1.1" id="文字" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 626.9 116.4" style={{ enableBackground: 'new 0 0 626.9 116.4' }} xmlSpace="preserve" fill="#5d6262">
                                                    <g>
                                                        <path d="M51,0C22.8,0,0,41.2,0,69.3c0,21.2,13,39.4,31.5,47.1c-4.6-4.8-7.4-11.3-7.4-18.5C24.1,83,36.1,71,51,71
		s26.9,12,26.9,26.9c0,7.2-2.8,13.7-7.4,18.5C89,108.7,102,90.5,102,69.3C101.9,41.2,79.1,0,51,0z" />
                                                        <g>
                                                            <g>
                                                                <path d="M175.7,84c-0.9,1.7-2.1,3.1-3.7,4.2s-3.5,2.1-5.7,2.7s-4.6,0.9-7.3,0.9c-3.8,0-7.1-0.5-10.1-1.4
				c-2.9-1-5.6-2.6-8.1-4.9l4.3-4.3c1.7,1.9,3.8,3.2,6.1,4s4.9,1.2,7.7,1.2c3.7,0,6.5-0.6,8.6-1.9s3.2-3.3,3.2-5.9
				c0-1.9-0.6-3.4-1.8-4.5s-3-1.7-5.6-2l-7.3-0.6c-4.3-0.4-7.6-1.5-9.9-3.5c-2.2-1.9-3.4-4.8-3.4-8.4c0-2,0.4-3.9,1.2-5.4
				c0.8-1.6,1.9-2.9,3.4-4c1.4-1.1,3.2-2,5.2-2.6s4.2-0.9,6.5-0.9c3.2,0,6.1,0.4,8.9,1.2c2.7,0.8,5.1,2,7.1,3.6l-4.2,4.2
				c-1.6-1.2-3.3-2.1-5.4-2.7c-2-0.6-4.2-0.9-6.4-0.9c-3.2,0-5.7,0.6-7.4,1.9c-1.7,1.3-2.5,3.1-2.5,5.4c0,1.9,0.6,3.4,1.8,4.5
				s3.2,1.7,6,2l7.1,0.6c1.9,0.2,3.7,0.5,5.3,1s3,1.2,4.1,2.2s2,2.2,2.7,3.6c0.6,1.4,0.9,3.2,0.9,5.2C177,80.4,176.6,82.3,175.7,84z
				" />
                                                                <path d="M202.6,91.2c-1.9,0-3.6-0.3-5-0.9s-2.6-1.5-3.5-2.6s-1.6-2.3-2.1-3.8c-0.5-1.4-0.7-3-0.7-4.6V52.2h-5.6v-4.9
				h5.6v-14h6.5v13.9h9.5v4.9h-9.5v27c0,2,0.5,3.6,1.4,4.8c1,1.1,2.5,1.7,4.6,1.7h3.4v5.6H202.6z" />
                                                                <path d="M245.7,91.2v-4.9c-1.6,1.8-3.4,3.2-5.5,4.1s-4.4,1.4-7,1.4c-4.8,0-8.6-1.4-11.4-4.1
				c-1.6-1.6-2.7-3.4-3.4-5.4c-0.7-2.1-1.1-4.4-1.1-7V46.9h6.5v27.3c0,4,1,6.9,2.9,8.9s4.5,2.9,7.9,2.9s6-1,8-3s3-4.9,3-8.8V46.9
				h6.6v44.3H245.7z" />
                                                                <path d="M288.9,91.2v-5.3c-1.8,2.3-3.7,3.9-5.8,4.7c-2.1,0.8-4.3,1.2-6.8,1.2c-2.3,0-4.3-0.3-6.2-1
				c-1.9-0.7-3.4-1.6-4.6-2.8c-1.1-1.1-2-2.4-2.7-3.9c-0.7-1.4-1.2-3-1.6-4.6s-0.7-3.3-0.8-5.1c-0.1-1.7-0.2-3.5-0.2-5.2
				s0.1-3.5,0.2-5.2c0.1-1.7,0.4-3.4,0.8-5.1c0.4-1.6,0.9-3.2,1.6-4.6c0.7-1.4,1.6-2.7,2.7-3.9c1.2-1.2,2.7-2.1,4.6-2.8s3.9-1,6.2-1
				c2.5,0,4.7,0.4,6.8,1.1c2,0.8,4,2.3,5.8,4.5l-0.1-24.9h6.6v64.1h-6.5V91.2z M288.4,62.8c-0.2-2-0.7-3.8-1.5-5.4
				c-0.8-1.6-1.9-2.8-3.4-3.8s-3.4-1.4-5.8-1.4s-4.3,0.5-5.8,1.4c-1.5,1-2.6,2.2-3.4,3.8s-1.3,3.4-1.6,5.4c-0.3,2-0.4,4.1-0.4,6.3
				s0.1,4.3,0.4,6.3s0.8,3.8,1.6,5.4c0.8,1.6,1.9,2.8,3.4,3.8s3.4,1.4,5.8,1.4s4.3-0.5,5.8-1.4c1.5-1,2.6-2.2,3.4-3.8
				c0.8-1.6,1.3-3.4,1.5-5.4s0.4-4.1,0.4-6.3S288.7,64.8,288.4,62.8z" />
                                                                <path d="M307.1,34.3V27h7.3v7.3H307.1z M307.5,91.2V46.9h6.5v44.3H307.5z" />
                                                                <path d="M359,78.7c-0.7,3-2.1,5.7-4.4,8c-1.6,1.6-3.4,2.8-5.5,3.7c-2.1,0.9-4.5,1.4-7.2,1.4s-5.1-0.5-7.2-1.4
				s-3.9-2.1-5.5-3.7c-1.1-1.1-2.1-2.4-2.7-3.7c-0.7-1.3-1.2-2.8-1.6-4.2c-0.4-1.5-0.7-3-0.8-4.6c-0.2-1.6-0.2-3.3-0.2-5
				s0.1-3.3,0.2-5c0.1-1.6,0.4-3.2,0.8-4.7s0.9-2.9,1.6-4.2s1.6-2.6,2.7-3.7c1.6-1.6,3.4-2.8,5.5-3.7s4.5-1.3,7.2-1.3
				s5.1,0.4,7.2,1.3c2.1,0.9,3.9,2.1,5.5,3.7c2.2,2.3,3.7,4.9,4.4,8c0.7,3,1,6.3,1,9.7S359.7,75.7,359,78.7z M353.5,65.3
				c-0.1-1.3-0.2-2.5-0.5-3.7c-0.2-1.2-0.6-2.3-1.1-3.4c-0.5-1-1.1-2-1.9-2.8c-2.2-2.2-4.9-3.2-8.1-3.2s-5.8,1.1-8,3.2
				c-0.8,0.8-1.5,1.8-1.9,2.8c-0.5,1.1-0.8,2.2-1.1,3.4c-0.3,1.2-0.4,2.4-0.5,3.7s-0.1,2.5-0.1,3.8s0,2.5,0.1,3.8s0.2,2.5,0.5,3.7
				s0.6,2.3,1.1,3.4s1.1,2,1.9,2.8c2.2,2.2,4.8,3.2,8,3.2s5.9-1.1,8.1-3.2c0.8-0.8,1.4-1.7,1.9-2.8c0.5-1.1,0.8-2.2,1.1-3.4
				c0.2-1.2,0.4-2.4,0.5-3.7c0.1-1.3,0.1-2.5,0.1-3.8S353.6,66.6,353.5,65.3z" />
                                                                <path d="M428.6,91.2l-5.1-14.5h-28l-5.1,14.5H383l23.6-64.1h5.8l23.5,64.1H428.6z M409.6,36.9l-12.1,33.8h23.9
				L409.6,36.9z" />
                                                            </g>
                                                            <path d="M442,48.9c-6.7,0-12.2-5.5-12.2-12.2s5.5-12.2,12.2-12.2s12.2,5.5,12.2,12.2C454.2,43.5,448.7,48.9,442,48.9z
			 M442,29.5c-4,0-7.2,3.2-7.2,7.2s3.2,7.2,7.2,7.2s7.2-3.2,7.2-7.2S446,29.5,442,29.5z" />
                                                        </g>
                                                        <g>
                                                            <path d="M501.1,65.2h-2.3l-3.5-12l-3.5,12h-2.3L485,48.1h2.7l3,12.2l3.4-12.2h2.1l3.4,12.2l3-12.2h2.7L501.1,65.2z" />
                                                            <path d="M508.7,59.8c0,1.1,0.3,1.9,0.8,2.5s1.3,0.9,2.3,0.9c0.7,0,1.2-0.1,1.7-0.3c0.4-0.2,0.9-0.5,1.3-0.9l1.6,1.5
			c-0.3,0.3-0.6,0.6-0.9,0.8c-0.3,0.2-0.6,0.4-1,0.6s-0.8,0.3-1.2,0.4s-0.9,0.1-1.4,0.1c-0.8,0-1.5-0.1-2.1-0.3
			c-0.7-0.2-1.2-0.6-1.7-1.1c-0.5-0.5-0.9-1.1-1.2-1.9c-0.3-0.8-0.4-1.8-0.4-2.9c0-2,0.5-3.5,1.4-4.6s2.2-1.6,3.8-1.6
			c0.8,0,1.5,0.1,2.2,0.4c0.6,0.3,1.2,0.7,1.6,1.2c0.4,0.5,0.8,1.1,1,1.8s0.3,1.5,0.3,2.4v1.1h-8.1V59.8z M514.1,57.2
			c0-0.2-0.1-0.5-0.3-0.8c-0.2-0.5-0.5-0.8-0.9-1.1s-0.9-0.4-1.5-0.4s-1.1,0.1-1.5,0.4c-0.4,0.3-0.7,0.7-0.9,1.1
			c-0.1,0.3-0.2,0.5-0.2,0.8c0,0.2-0.1,0.5-0.1,0.9h5.5C514.1,57.8,514.1,57.5,514.1,57.2z" />
                                                            <path d="M529.7,60.5c0,0.5-0.1,0.9-0.2,1.4c-0.1,0.5-0.2,0.9-0.4,1.3c-0.2,0.4-0.4,0.8-0.7,1.1s-0.8,0.6-1.3,0.8
			c-0.5,0.2-1.1,0.3-1.7,0.3c-0.7,0-1.3-0.1-1.8-0.3s-1-0.6-1.5-1.1v1.3h-2.4V48.1h2.4v6.1c0.4-0.5,0.9-0.9,1.4-1.1
			c0.5-0.2,1.1-0.3,1.8-0.3c0.6,0,1.2,0.1,1.7,0.3s0.9,0.5,1.3,0.8c0.3,0.3,0.5,0.7,0.7,1.1c0.2,0.4,0.3,0.8,0.4,1.3
			c0.1,0.4,0.2,0.9,0.2,1.4s0,1,0,1.4C529.7,59.6,529.7,60.1,529.7,60.5z M527.2,57.6c-0.1-0.5-0.2-0.9-0.3-1.3
			c-0.2-0.4-0.4-0.7-0.8-0.9c-0.3-0.2-0.8-0.3-1.3-0.3c-0.6,0-1,0.1-1.3,0.3s-0.6,0.5-0.8,0.9s-0.3,0.8-0.4,1.3
			c-0.1,0.5-0.1,1-0.1,1.5s0,1.1,0.1,1.5c0.1,0.5,0.2,0.9,0.4,1.3c0.2,0.4,0.4,0.7,0.8,0.9c0.3,0.2,0.8,0.3,1.3,0.3s1-0.1,1.3-0.3
			s0.6-0.5,0.8-0.9s0.3-0.8,0.3-1.3c0.1-0.5,0.1-1,0.1-1.5S527.3,58.1,527.2,57.6z" />
                                                            <path d="M540.5,65.2l-1-1.2c-0.1,0.1-0.3,0.2-0.5,0.4c-0.2,0.1-0.4,0.3-0.7,0.4s-0.6,0.2-0.9,0.3
			c-0.4,0.1-0.8,0.1-1.2,0.1c-1.3,0-2.2-0.3-2.9-1s-1-1.5-1-2.6c0-0.4,0.1-0.8,0.2-1.1c0.1-0.3,0.3-0.6,0.5-0.9
			c0.2-0.3,0.5-0.5,0.8-0.7s0.6-0.4,0.9-0.6c-0.1-0.2-0.3-0.3-0.4-0.5s-0.3-0.4-0.4-0.6c-0.1-0.2-0.2-0.4-0.3-0.7
			c-0.1-0.2-0.1-0.5-0.1-0.8c0-0.4,0.1-0.7,0.2-1.1c0.1-0.3,0.3-0.6,0.6-0.9c0.3-0.2,0.6-0.4,0.9-0.6c0.4-0.1,0.8-0.2,1.2-0.2
			s0.8,0.1,1.2,0.2s0.7,0.3,0.9,0.6c0.3,0.2,0.5,0.5,0.6,0.9c0.1,0.3,0.2,0.7,0.2,1.1c0,0.5-0.1,0.9-0.4,1.2
			c-0.2,0.3-0.5,0.6-0.9,0.9c0,0-0.1,0-0.2,0.1c-0.1,0.1-0.2,0.1-0.4,0.2c-0.1,0.1-0.3,0.2-0.4,0.3l2.5,3c0.2-0.3,0.4-0.7,0.5-1
			c0.1-0.4,0.2-0.8,0.2-1.4h1.7c0,0.8-0.1,1.5-0.3,2.1s-0.5,1.2-0.9,1.6l2,2.4L540.5,65.2L540.5,65.2z M535.6,59.4
			c-0.2,0.2-0.4,0.3-0.6,0.5c-0.2,0.2-0.4,0.3-0.5,0.5c-0.2,0.2-0.3,0.4-0.4,0.6c-0.1,0.2-0.1,0.5-0.1,0.7c0,0.6,0.2,1.1,0.6,1.5
			c0.4,0.4,0.9,0.6,1.6,0.6c0.4,0,0.8-0.1,1.2-0.2c0.4-0.1,0.8-0.4,1.1-0.7L535.6,59.4z M537.2,54.9c-0.2-0.2-0.5-0.3-0.9-0.3
			s-0.7,0.1-0.9,0.3s-0.3,0.5-0.3,0.8c0,0.2,0.1,0.5,0.2,0.7c0.2,0.2,0.4,0.6,0.7,0.9c0.1,0,0.1-0.1,0.2-0.2
			c0.1-0.1,0.2-0.1,0.2-0.2c0.1,0,0.1-0.1,0.1-0.1c0.2-0.2,0.4-0.3,0.6-0.5c0.2-0.2,0.3-0.4,0.3-0.7
			C537.6,55.4,537.5,55.1,537.2,54.9z" />
                                                            <path d="M557.2,61.4c-0.2,0.8-0.6,1.4-1.2,2c-0.7,0.7-1.4,1.2-2.2,1.5s-1.6,0.5-2.5,0.5s-1.7-0.2-2.5-0.5
			s-1.4-0.8-2-1.4c-0.4-0.4-0.7-0.9-1-1.3c-0.2-0.5-0.4-1-0.5-1.5s-0.2-1.1-0.2-1.8s0-1.4,0-2.2s0-1.6,0-2.2c0-0.7,0.1-1.3,0.2-1.8
			c0.1-0.5,0.3-1,0.5-1.5s0.5-0.9,1-1.3c0.6-0.6,1.3-1,2-1.4c0.7-0.3,1.6-0.5,2.5-0.5s1.7,0.1,2.4,0.4c0.7,0.3,1.3,0.7,1.8,1.1
			c0.5,0.5,0.9,1,1.3,1.7c0.3,0.6,0.5,1.3,0.7,2.1h-2.6c-0.2-0.9-0.6-1.7-1.2-2.2c-0.6-0.5-1.4-0.8-2.4-0.8c-0.5,0-1,0.1-1.4,0.3
			s-0.8,0.4-1.1,0.8c-0.2,0.2-0.4,0.5-0.5,0.7c-0.1,0.3-0.2,0.6-0.3,1s-0.1,0.9-0.2,1.4c0,0.6,0,1.3,0,2.1c0,0.9,0,1.6,0,2.2
			c0,0.6,0.1,1.1,0.2,1.5c0.1,0.4,0.2,0.7,0.3,1s0.3,0.5,0.5,0.7c0.3,0.3,0.7,0.6,1.1,0.8c0.4,0.2,0.9,0.3,1.4,0.3
			c0.6,0,1.1-0.1,1.6-0.3s0.9-0.5,1.2-0.9s0.5-0.8,0.7-1.2s0.2-0.9,0.2-1.5v-1h-3.6V56h6.2v2.6C557.5,59.7,557.4,60.6,557.2,61.4z" />
                                                            <path d="M567.7,55.9c-0.3-0.3-0.5-0.5-0.8-0.6s-0.6-0.2-1-0.2c-0.7,0-1.2,0.2-1.7,0.7s-0.7,1.2-0.7,2v7.4H561V53h2.4
			v1.3c0.3-0.4,0.8-0.8,1.3-1.1c0.6-0.3,1.2-0.4,1.9-0.4c0.6,0,1.1,0.1,1.6,0.3s0.9,0.5,1.3,0.9L567.7,55.9z" />
                                                            <path d="M577.9,65.2v-1.1c-0.4,0.4-0.9,0.8-1.4,1s-1.1,0.3-1.8,0.3c-0.8,0-1.4-0.1-1.9-0.3c-0.5-0.2-1-0.5-1.3-0.8
			c-0.6-0.7-1-1.5-1-2.6c0-1,0.3-1.9,1-2.5c0.7-0.7,1.7-1,3.2-1h3.2v-0.9c0-0.8-0.2-1.3-0.6-1.7c-0.4-0.4-1.1-0.6-2.1-0.6
			c-0.7,0-1.2,0.1-1.6,0.3c-0.4,0.2-0.7,0.5-1.1,0.9l-1.6-1.5c0.6-0.7,1.2-1.1,1.9-1.4c0.7-0.2,1.5-0.4,2.5-0.4c3.3,0,5,1.4,5,4.2
			v8.1H577.9z M577.8,59.8H575c-0.7,0-1.3,0.2-1.6,0.5c-0.4,0.3-0.6,0.7-0.6,1.3s0.2,1,0.5,1.3c0.4,0.3,0.9,0.4,1.7,0.4
			c0.5,0,0.9,0,1.2-0.1s0.7-0.3,1-0.6c0.4-0.4,0.5-1,0.5-1.8L577.8,59.8L577.8,59.8z" />
                                                            <path d="M594,60.5c0,0.5-0.1,0.9-0.2,1.4c-0.1,0.5-0.2,0.9-0.4,1.3c-0.2,0.4-0.4,0.8-0.7,1.1s-0.8,0.6-1.3,0.8
			s-1.1,0.3-1.7,0.3c-0.7,0-1.2-0.1-1.8-0.3c-0.5-0.2-1-0.6-1.4-1.1v6.2h-2.4V53h2.4v1.3c0.5-0.6,0.9-0.9,1.5-1.1
			c0.5-0.2,1.1-0.3,1.8-0.3c0.6,0,1.2,0.1,1.7,0.3s0.9,0.5,1.3,0.8c0.3,0.3,0.5,0.7,0.7,1.1c0.2,0.4,0.3,0.8,0.4,1.3
			c0.1,0.4,0.2,0.9,0.2,1.4s0,1,0,1.4C594,59.6,594,60.1,594,60.5z M591.5,57.6c-0.1-0.5-0.2-0.9-0.3-1.3c-0.2-0.4-0.4-0.7-0.8-0.9
			c-0.3-0.2-0.8-0.3-1.3-0.3c-0.6,0-1,0.1-1.3,0.3s-0.6,0.5-0.8,0.9s-0.3,0.8-0.4,1.3c-0.1,0.5-0.1,1-0.1,1.5s0,1.1,0.1,1.5
			c0.1,0.5,0.2,0.9,0.4,1.3c0.2,0.4,0.4,0.7,0.8,0.9c0.3,0.2,0.8,0.3,1.3,0.3s1-0.1,1.3-0.3s0.6-0.5,0.8-0.9s0.3-0.8,0.3-1.3
			c0.1-0.5,0.1-1,0.1-1.5S591.5,58.1,591.5,57.6z" />
                                                            <path d="M604.7,65.2v-7.5c0-0.9-0.2-1.6-0.7-2s-1.1-0.6-1.7-0.6c-0.7,0-1.3,0.2-1.8,0.6c-0.5,0.4-0.7,1.1-0.7,2v7.5
			h-2.4V48.1h2.4v6.1c0.4-0.5,0.9-0.8,1.4-1s1.1-0.3,1.7-0.3c0.7,0,1.3,0.1,1.8,0.3s1,0.5,1.3,0.9c0.4,0.4,0.6,0.9,0.8,1.4
			c0.2,0.6,0.3,1.2,0.3,1.8v7.8L604.7,65.2L604.7,65.2z" />
                                                            <path d="M610.8,50.6V48h2.6v2.6H610.8z M610.9,65.2V53h2.4v12.2H610.9z" />
                                                            <path d="M624.3,64.9c-0.6,0.3-1.4,0.4-2.2,0.4c-0.7,0-1.4-0.1-2-0.3c-0.6-0.2-1.2-0.6-1.7-1.1
			c-0.5-0.5-0.9-1.1-1.2-1.9c-0.3-0.8-0.5-1.8-0.5-2.9c0-1.1,0.2-2.1,0.5-2.9c0.3-0.8,0.7-1.4,1.2-1.9s1.1-0.8,1.7-1.1
			c0.6-0.2,1.3-0.3,2-0.3c0.8,0,1.6,0.1,2.2,0.4s1.2,0.7,1.8,1.3l-1.7,1.6c-0.4-0.4-0.7-0.7-1.1-0.9c-0.3-0.2-0.8-0.3-1.2-0.3
			c-1,0-1.7,0.4-2.3,1.1c-0.3,0.3-0.5,0.7-0.6,1.2s-0.2,1-0.2,1.8c0,0.7,0.1,1.3,0.2,1.8c0.1,0.5,0.3,0.9,0.6,1.2
			c0.5,0.7,1.3,1.1,2.3,1.1c0.5,0,0.9-0.1,1.2-0.3s0.7-0.5,1.1-0.9l1.7,1.6C625.5,64.2,624.9,64.7,624.3,64.9z" />
                                                            <path d="M499.3,82.4c0,0.6-0.1,1.3-0.2,1.9s-0.3,1.2-0.5,1.8c-0.2,0.6-0.6,1.1-1.1,1.5c-0.5,0.5-1.2,0.9-1.9,1.2
			s-1.6,0.4-2.5,0.4h-6V72.1h6c0.9,0,1.7,0.1,2.5,0.4s1.4,0.7,1.9,1.2s0.8,1,1.1,1.5c0.2,0.5,0.4,1.1,0.5,1.7s0.1,1.2,0.2,1.8
			c0,0.6,0,1.2,0,1.8C499.3,81.2,499.3,81.8,499.3,82.4z M496.6,77.6c-0.1-0.8-0.3-1.5-0.8-2c-0.7-0.8-1.7-1.2-2.9-1.2h-3.1v12.4
			h3.1c1.2,0,2.2-0.4,2.9-1.2c0.4-0.5,0.7-1.2,0.8-2.1c0.1-0.9,0.1-1.9,0.1-3.1C496.7,79.4,496.7,78.4,496.6,77.6z" />
                                                            <path d="M504.7,83.8c0,1.1,0.3,1.9,0.8,2.5s1.3,0.9,2.3,0.9c0.7,0,1.2-0.1,1.7-0.3c0.4-0.2,0.9-0.5,1.3-0.9l1.6,1.5
			c-0.3,0.3-0.6,0.6-0.9,0.8c-0.3,0.2-0.6,0.4-1,0.6s-0.8,0.3-1.2,0.4c-0.4,0.1-0.9,0.1-1.4,0.1c-0.8,0-1.5-0.1-2.1-0.3
			c-0.7-0.2-1.2-0.6-1.7-1.1s-0.9-1.1-1.2-1.9c-0.3-0.8-0.4-1.8-0.4-2.9c0-2,0.5-3.5,1.4-4.6s2.2-1.6,3.8-1.6c0.8,0,1.5,0.1,2.2,0.4
			c0.6,0.3,1.2,0.7,1.6,1.2s0.8,1.1,1,1.8c0.2,0.7,0.3,1.5,0.3,2.4v1.1L504.7,83.8L504.7,83.8z M510.1,81.2c0-0.2-0.1-0.5-0.3-0.8
			c-0.2-0.5-0.5-0.8-0.9-1.1c-0.4-0.3-0.9-0.4-1.5-0.4s-1.1,0.1-1.5,0.4s-0.7,0.7-0.9,1.1c-0.1,0.3-0.2,0.5-0.2,0.8
			c0,0.2-0.1,0.5-0.1,0.9h5.5C510.2,81.8,510.1,81.5,510.1,81.2z" />
                                                            <path d="M524.4,87.2c-0.3,0.5-0.6,0.9-1.1,1.2c-0.4,0.3-1,0.6-1.6,0.7c-0.6,0.2-1.3,0.2-2,0.2c-1,0-1.9-0.1-2.8-0.3
			c-0.8-0.2-1.6-0.7-2.4-1.4l1.6-1.6c0.5,0.5,1.1,0.9,1.7,1c0.6,0.2,1.2,0.3,1.8,0.3c0.8,0,1.4-0.1,1.9-0.4s0.8-0.7,0.8-1.3
			c0-0.4-0.1-0.7-0.4-1c-0.2-0.2-0.7-0.4-1.3-0.4l-1.9-0.2c-1.2-0.1-2-0.4-2.7-0.9c-0.6-0.5-0.9-1.3-0.9-2.4c0-0.6,0.1-1.1,0.4-1.6
			c0.2-0.5,0.6-0.9,1-1.2s0.9-0.6,1.5-0.7c0.6-0.2,1.1-0.3,1.8-0.3c0.9,0,1.8,0.1,2.5,0.3c0.8,0.2,1.4,0.6,2,1.1l-1.5,1.6
			c-0.4-0.3-0.8-0.5-1.4-0.7c-0.5-0.1-1.1-0.2-1.6-0.2c-0.7,0-1.3,0.1-1.7,0.4s-0.6,0.7-0.6,1.1s0.1,0.7,0.3,0.9
			c0.2,0.2,0.7,0.4,1.3,0.5l1.9,0.2c1.2,0.1,2,0.4,2.7,1c0.6,0.6,0.9,1.4,0.9,2.5C524.8,86.1,524.7,86.7,524.4,87.2z" />
                                                            <path d="M527.7,74.6V72h2.6v2.6H527.7z M527.8,89.2V77h2.4v12.2H527.8z" />
                                                            <path d="M543.1,91.2c-0.2,0.6-0.6,1.2-1,1.6c-0.4,0.5-1,0.8-1.6,1.1c-0.6,0.3-1.4,0.4-2.2,0.4c-0.9,0-1.7-0.1-2.4-0.4
			c-0.6-0.2-1.3-0.7-1.9-1.2l1.6-1.6c0.4,0.4,0.8,0.6,1.2,0.8c0.4,0.2,0.9,0.3,1.5,0.3c0.5,0,0.9-0.1,1.3-0.3
			c0.4-0.2,0.7-0.4,0.9-0.7c0.2-0.3,0.4-0.6,0.5-1s0.2-0.8,0.2-1.2v-1.6c-0.4,0.5-0.9,0.9-1.4,1.1s-1.1,0.3-1.7,0.3
			c-0.6,0-1.2-0.1-1.7-0.3c-0.5-0.2-0.9-0.5-1.2-0.8c-0.6-0.6-1-1.3-1.1-2.1c-0.2-0.8-0.2-1.8-0.2-2.8s0.1-2,0.2-2.8
			c0.2-0.8,0.5-1.5,1.1-2.1c0.7-0.7,1.7-1.1,2.9-1.1c0.7,0,1.2,0.1,1.7,0.3c0.5,0.2,1,0.6,1.5,1.1V77h2.4v12.1
			C543.4,89.9,543.3,90.6,543.1,91.2z M540.9,81.5c0-0.5-0.2-0.9-0.3-1.2c-0.2-0.4-0.4-0.6-0.8-0.9c-0.3-0.2-0.8-0.3-1.3-0.3
			c-0.6,0-1,0.1-1.3,0.3c-0.3,0.2-0.6,0.5-0.7,0.9c-0.2,0.4-0.3,0.8-0.3,1.2c-0.1,0.5-0.1,0.9-0.1,1.4s0,1,0.1,1.4
			c0.1,0.5,0.2,0.9,0.3,1.2c0.2,0.4,0.4,0.6,0.7,0.9c0.3,0.2,0.8,0.3,1.3,0.3s1-0.1,1.3-0.3c0.3-0.2,0.6-0.5,0.8-0.9
			s0.3-0.8,0.3-1.2s0.1-0.9,0.1-1.4S540.9,82,540.9,81.5z" />
                                                            <path d="M554.7,89.2v-7.4c0-0.9-0.2-1.6-0.7-2.1c-0.5-0.4-1.1-0.6-1.7-0.6c-0.7,0-1.3,0.2-1.8,0.7
			c-0.5,0.4-0.7,1.1-0.7,2.1v7.4h-2.4V77h2.4v1.2c0.4-0.5,0.9-0.8,1.5-1s1.2-0.3,1.8-0.3s1.2,0.1,1.7,0.3c0.5,0.2,0.9,0.5,1.3,0.8
			c0.5,0.5,0.8,1,1,1.5c0.2,0.6,0.3,1.2,0.3,1.9v7.8C557.4,89.2,554.7,89.2,554.7,89.2z" />
                                                        </g>
                                                    </g>
                                                </svg>
                                            </AniLink>
                                        </h1>
                                    </div>
                                </header>
                                <div className="BlogPost__asideBody">
                                    <div className="Sidebar">
                                        {/*
                                        <div className="Sidebar__search">
                                            <form action method="get" className="searchForm">
                                                <input type="text" value="" name="s" id="s" placeholder="検索…" />
                                                <input type="submit" id="searchSubmit" value="&#xf002;" className="fas" />
                                            </form>
                                        </div>
                                        */}
                                        <div className="Sidebar__container">
                                            <NewArticle></NewArticle>
                                        </div>
                                        <div className="Sidebar__container">
                                            <NewCategory></NewCategory>
                                        </div>
                                        <div className="Sidebar__container">
                                            <NewArchive></NewArchive>
                                        </div>
                                    </div>
                                </div>
                            </aside>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    </Layout >
)

export const query = graphql`
    query($id: String!) {
        wordpressPost(id: { eq: $id }) {
            title
            dateJP:date(formatString: "YYYY年MM月DD日")
            date
            categories {
                name
                slug
                id
            }
            content
            excerpt
            featured_media {
                localFile {
                    childImageSharp {
                        fluid(maxWidth: 1000) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
                alt_text
                source_url
                media_details {
                    width
                    height
                }
            }
        }
        allWordpressPost(sort: {order: DESC, fields: date}, skip: 0, limit: 3) {
            edges {
                node {
                    title
                    id
                    slug
                }
            }
        }
    }
`